
<template>
   <v-app>
     <div class="row">
       <div class="col-12">
         <div class="card">
           <div class="card-header">
             <div class="card-title pt-3 px-3 d-flex justify-content-between">
               <div class="breadcrumb-left">
                 <h4>
                   {{ title }} candidate inquiry info
                 </h4>
                 <div class="breadcrumb-sub-header">
                   <router-link to="/dashboard">Dashboard</router-link>
                   \
                   Candidate inquiry info
                 </div>
               </div>
             </div>
           </div>
           <div class="card-body">
             <v-row>
               <v-col cols="12" md="4">
                 <v-select
                     v-model="candidateInquiryInfo.institution_id"
                     :error="$v.candidateInquiryInfo.institution_id.$error"
                     @change="getAllPrograms"
                     :items="institutions"
                     item-text="title"
                     item-value="id"
                     outlined
                     dense
                 >
                   <template v-slot:label>
                     Institution <span class="text-danger">*</span>
                   </template>
                 </v-select>
               </v-col>

               <v-col cols="12" md="4">
                 <v-select
                     :items="education_levels"
                     clearable
                     dense
                     item-text="title"
                     item-value="id"
                     label="Education level"
                     outlined
                     class="custom-select"
                     v-model="candidateInquiryInfo.education_level_id"
                     :error="$v.candidateInquiryInfo.education_level_id.$error"
                 >
                   <template v-slot:label>
                     Education level <span class="text-danger">*</span>
                   </template>
                 </v-select>
               </v-col>

               <v-col cols="12" md="4">
                 <v-select
                     v-model="candidateInquiryInfo.academic_program_id"
                     :error="$v.candidateInquiryInfo.academic_program_id.$error"
                     :items="programs"
                     item-text="title"
                     item-value="id"
                     outlined
                     :loading="isProgramLoading"
                     dense
                 >
                   <template v-slot:label>
                     Program <span class="text-danger"></span>
                   </template>
                   <template v-slot:item="data">
                     <v-list-item-content>
                       <v-list-item-title v-html="data.item.title_location"></v-list-item-title>
                     </v-list-item-content>
                   </template>
                 </v-select>
               </v-col>

               <v-col md="4">
                 <v-menu
                     :close-on-content-click="false"
                     min-width="auto"
                     offset-y
                     ref="menu"
                     transition="scale-transition"
                     v-model="menu"
                 >
                   <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                         :value="formattedIntakeInfoDate"
                         prepend-inner-icon="mdi-calendar"
                         label="Intake info"
                         v-bind="attrs"
                         v-on="on"
                         readonly
                         outlined
                         dense
                     >
                       <template v-slot:label>
                         Intake info <span class="text-danger"></span>
                       </template>
                     </v-text-field>
                   </template>
                   <v-date-picker
                       no-title
                       @input="menu = false"
                       v-model="candidateInquiryInfo.intake_info"
                       type="month"
                   ></v-date-picker>
                 </v-menu>
               </v-col>

               <v-col md="4">
                 <v-menu
                     :close-on-content-click="false"
                     min-width="auto"
                     offset-y
                     ref="menu"
                     transition="scale-transition"
                     v-model="menu1"
                 >
                   <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                         v-model="candidateInquiryInfo.visa_study_permit"
                         prepend-inner-icon="mdi-calendar"
                         label="Study permit expired"
                         v-bind="attrs"
                         v-on="on"
                         readonly
                         outlined
                         dense
                     >
                       <template v-slot:label>
                         Study permit expired <span class="text-danger"></span>
                       </template>
                     </v-text-field>
                   </template>
                   <v-date-picker
                       no-title
                       @input="menu1 = false"
                       v-model="candidateInquiryInfo.visa_study_permit"
                   ></v-date-picker>
                 </v-menu>
               </v-col>

               <v-col md="4">
                 <v-select
                     v-model="candidateInquiryInfo.status_id"
                     :items="statuses"
                     item-text="title"
                     item-value="id"
                     label="Status"
                     outlined
                     dense
                 ></v-select>
               </v-col>

               <v-col md="4">
                 <v-text-field
                     v-model="candidateInquiryInfo.past_consultancy"
                     label="Past consultancy"
                     outlined
                     dense
                 ></v-text-field>
               </v-col>

               <v-col md="4">
                 <v-select
                     v-model="candidateInquiryInfo.dli_change"
                     :items="status"
                     item-text="name"
                     label="DLI change"
                     outlined
                     dense
                 ></v-select>
               </v-col>

               <v-col md="4">
                 <v-select
                     v-model="candidateInquiryInfo.marital_status"
                     :error="$v.candidateInquiryInfo.marital_status.$error"
                     :items="marriedStatus"
                     item-text="name"
                     label="Marital status"
                     outlined
                     dense
                 >
                   <template v-slot:label>
                     Marital status <span class="text-danger">*</span>
                   </template>
                 </v-select>
               </v-col>

               <v-col md="4">
                 <v-select
                     v-model="candidateInquiryInfo.passport_status"
                     :items="passportStatus"
                     item-text="name"
                     label="Passport status"
                     outlined
                     dense
                 >
                   <template v-slot:label>
                     Passport status <span class="text-danger"></span>
                   </template>
                 </v-select>
               </v-col>


               <v-col md="4">
                 <v-text-field
                     v-model="candidateInquiryInfo.location"
                     label="Canadian address"
                     outlined
                     dense
                 >
                   <template v-slot:label>
                     Canadian address <span class="text-danger"></span>
                   </template>
                 </v-text-field>
               </v-col>

               <v-col md="4">
                 <v-menu
                     :close-on-content-click="false"
                     min-width="auto"
                     offset-y
                     ref="menu"
                     transition="scale-transition"
                     v-model="menu2"
                 >
                   <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                         v-model="candidateInquiryInfo.current_intake_end_date"
                         prepend-inner-icon="mdi-calendar"
                         label="Study permit expired"
                         v-bind="attrs"
                         v-on="on"
                         readonly
                         outlined
                         dense
                     >
                       <template v-slot:label>
                         Current intake end date <span class="text-danger"></span>
                       </template>
                     </v-text-field>
                   </template>
                   <v-date-picker
                       no-title
                       @input="menu2 = false"
                       v-model="candidateInquiryInfo.current_intake_end_date"
                   ></v-date-picker>
                 </v-menu>
               </v-col>

               <v-col md="4">
                 <v-menu
                     :close-on-content-click="false"
                     min-width="auto"
                     offset-y
                     ref="menu"
                     transition="scale-transition"
                     v-model="menu3"
                 >
                   <template v-slot:activator="{ on, attrs }">
                     <v-text-field
                         v-model="candidateInquiryInfo.intended_intake_start_date"
                         prepend-inner-icon="mdi-calendar"
                         label="Study permit expired"
                         v-bind="attrs"
                         v-on="on"
                         readonly
                         outlined
                         dense
                     >
                       <template v-slot:label>
                         Intended intake start date <span class="text-danger"></span>
                       </template>
                     </v-text-field>
                   </template>
                   <v-date-picker
                       no-title
                       @input="menu3 = false"
                       v-model="candidateInquiryInfo.intended_intake_start_date"
                       :min="candidateInquiryInfo.current_intake_end_date"
                   ></v-date-picker>
                 </v-menu>
               </v-col>
               <v-col cols="4" class="mt-2">
                  <span class="text-h6 badge" v-bind:class="{'badge-success': countDate <= 150, 'badge-danger': countDate > 150}"> {{countDate}} days</span>
               </v-col>
             </v-row>

             <v-row>

               <v-col cols="12" md="6">
                 Reason for contacting
                 <ckeditor
                     :config="editorConfig2"
                     v-model="candidateInquiryInfo.reason_of_changing"
                 >
                 </ckeditor>
               </v-col>

               <v-col cols="12" md="6">
                 Application instruction notes
                 <ckeditor
                     :config="editorConfig"
                     v-model="candidateInquiryInfo.remarks"
                 >
                 </ckeditor>
               </v-col>

               <v-col cols="12" md="6">
                 Tuition payment deadline details
                 <ckeditor
                     :config="editorConfig1"
                     v-model="candidateInquiryInfo.want_to_apply"
                 >
                 </ckeditor>
               </v-col>

               <v-col cols="12" md="6">
                 Solution
                 <ckeditor
                     :config="editorConfig3"
                     v-model="candidateInquiryInfo.solution"
                 >
                 </ckeditor>
               </v-col>
             </v-row>
             <v-row>
               <v-col cols="12 text-right">
                 <v-btn color="mr-2" text dark medium @click="goBack">
                   Cancel
                 </v-btn>
                 <v-btn
                     v-if="checkIsAccessible('candidate-inquiry-info', 'create') || checkIsAccessible('candidate-inquiry-info', 'edit')"
                     class="btn btn-primary"
                     @click="createOrUpdate()"
                     :loading="loading"
                 >
                   {{edit ? "Update" : "Save"}}
                 </v-btn>
               </v-col>
             </v-row>
           </div>
         </div>
       </div>
     </div>
   </v-app>
</template>
<script>
import { required} from "vuelidate/lib/validators";
import AcademicProgramService from "@/services/academic/program/AcademicProgramService";
import InstitutionService from "@/services/academic/institution/InstitutionService";
import EducationLevelService from "@/services/academic/education-level/EducationLevelService";
import CandidateInquiryInfo from "@/services/candidate-inquiry-info/CandidateInquiryInfo";
import VisaApplicationStatusService from "@/services/visa/application-status/VisaApplicationStatusService";
import VisaApplicationStatusStateService from "@/services/visa/application-status/VisaApplicationStatusStateService";

const candidateInquiryInfo = new CandidateInquiryInfo();
const academicProgram = new AcademicProgramService();
const institution = new InstitutionService();
const educationLevel = new EducationLevelService();
const visaApplicationStatus = new VisaApplicationStatusService();
const statusStateService = new VisaApplicationStatusStateService();
export default {
  name: "CreateAndUpdate",
  data() {
    return {
      title: '',
      menu: false,
      id: null,
      loading: false,
      edit: false,
      menu1: false,
      menu2: false,
      menu3: false,
      programs: [],
      isProgramLoading: false,
      errors: [],
      institutions: [],
      visaApplicationStatuses: [],
      candidateInquiryInfo: {
        institution_id: "",
        academic_program_id: "",
        education_level_id: "",
        intake_info: "",
        status_id: "",
        dli_change: "",
        marital_status: "",
        passport_status: "",
        location: "",
        want_to_apply: "",
        past_consultancy: "",
        visa_study_permit: "",
        current_intake_end_date: "",
        intended_intake_start_date: "",
        reason_of_changing: "",
        solution: "",
        remarks: "",
      },
      editorConfig: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      editorConfig1: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      editorConfig2: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      editorConfig3: {
        versionCheck: false,
        toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList' ] ]
      },
      statuses: [],
      status: [
        { name: 'Done'},
        { name: 'Not yet'},
        { name: 'Not Required'},
      ],
      marriedStatus: [
        { name: 'Single/ Never married' },
        { name: 'Married- Both in Canada' },
        { name: 'Married but partner is in Nepal' },
      ],
      passportStatus: [
        { name: 'Same passport as entry' },
        { name: 'Multiple passport ( old expired or damaged)' },
      ],
    }
  },

  validations: {
    candidateInquiryInfo: {
      institution_id: {required},
      academic_program_id: {required},
      education_level_id: {required},
      marital_status: {required},
    }
  },

  computed: {
    formattedIntakeInfoDate() {
      return this.candidateInquiryInfo.intake_info ? this.$moment(this.candidateInquiryInfo.intake_info).format('MMM YYYY') : '';
    },
    countDate() {
      const current_intake_end_date = this.candidateInquiryInfo.current_intake_end_date ? this.$moment(this.candidateInquiryInfo.current_intake_end_date) : null;
      const intended_intake_start_date = this.candidateInquiryInfo.intended_intake_start_date ? this.$moment(this.candidateInquiryInfo.intended_intake_start_date) : null;
      if(current_intake_end_date && intended_intake_start_date) {
        return intended_intake_start_date.diff(current_intake_end_date, "days");
      }
      return 0;
    }
  },

  mounted() {
    if(this.$route.params.id) {
      this.id = this.$route.params.id;
      this.title = "Edit";
      this.edit = true;
      this.getCandidateInquiryInfo();
    }
    this.getAllEducationLevel();
    this.getAllInstitutions();
    this.getApplicationState();
  },

  methods: {
    getCandidateInquiryInfo() {
      candidateInquiryInfo
          .show(this.id)
          .then(response => {
            this.candidateInquiryInfo = response.data.candidateInquiryInfo;
            this.getAllPrograms();
          }).catch(() => {});
    },

    getAllEducationLevel() {
      educationLevel
          .paginate({})
          .then(response => {
            this.education_levels = response.data.data;
          }).catch(() => {});
    },

    getAllInstitutions() {
      institution.getByCountry(39).then(response => {
        this.institutions = response.data.institutions;
      }).catch(() => {});
    },

    getAllPrograms() {
      if(this.candidateInquiryInfo.institution_id){
        this.isProgramLoading = true;
        academicProgram.getByInstitution(this.candidateInquiryInfo.institution_id).then(response => {
          this.programs = response.data.programs;
          this.isProgramLoading = false;
        }).catch(error => {
          this.isProgramLoading = false;
        });
      }

    },

    getApplicationState() {
      visaApplicationStatus.getByCountry(39, 'candidate_inquiry_info').then((response) => {
        this.visaApplicationStatuses = response.data.applications_statuses;
        if(this.visaApplicationStatuses) {
          this.getApplicationStateStatus();
        }
      }).catch(() => {});
    },

    getApplicationStateStatus() {
      statusStateService.getAllVisaStateStatus(this.visaApplicationStatuses[0].id).then((response) => {
        this.statuses = response.data.data;
      }).catch(() => {});
    },

    goBack() {
      this.$tabs.close().then(() => {
        this.$tabs.open({name: "future-lead"})
      })
    },

    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      } else {
        if (!this.edit) this._create();
        else this.update();
      }
    },

    _create: function () {
      this.loading = true;
      candidateInquiryInfo
          .create(this.candidateInquiryInfo)
          .then(response => {
            this.$snotify.success(`Created Successfully`);
            this.loading = false;
            this.resetForm();
            this.goBack();
          })
          .catch(error => {
            this.loading = false;
          })
    },

    update: function () {
      this.loading = true;
      candidateInquiryInfo
          .update(this.candidateInquiryInfo.id, this.candidateInquiryInfo)
          .then(response => {
            this.$snotify.success(`Update Successfully`);
            this.errors = [];
            this.candidateInquiryInfo.auto_generate = false;
            this.loading = false;
            this.resetForm();
            this.goBack();
          }).catch(error => {
            this.loading = false;
          })
    },

    resetForm() {
      this.$v.$reset();
      this.errors = [];
      this.candidateInquiryInfo = {
        institution_id: "",
        academic_program_id: "",
        education_level_id: "",
        intake_info: "",
        status_id: "",
        dli_change: "",
        marital_status: "",
        passport_status: "",
        location: "",
        want_to_apply: "",
        past_consultancy: "",
        visa_study_permit: "",
        current_intake_end_date: "",
        intended_intake_start_date: "",
        reason_of_changing: "",
        solution: "",
        remarks: "",
      };
    },
  },
}
</script>
